import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getAnalytics } from "firebase/analytics";
// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDEhp3bgLCtaxgd1XnS1Iu_tMofC0xgrIY",
  authDomain: "pictrain.firebaseapp.com",
  projectId: "pictrain",
  storageBucket: "pictrain.appspot.com",
  messagingSenderId: "1073944580948",
  appId: "1:1073944580948:web:0115ae8039b0fa29fc6ec1"
};

const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);
const auth = getAuth(app);
const analytics = getAnalytics(app);

export { app, auth, firestore };
