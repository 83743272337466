import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';
import LandingPage from './LandingPage';
import Checkout from './Checkout';
import TermsOfService from './TermsOfService';
import Success from './Success';  // Import the Success component
import WebApp from './WebApp';  // Import the WebApp component

const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Arial', sans-serif;
    margin: 0;
    padding: 0;
    background-color: #000000;
    color: #fff;
  }
`;

const AppContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

function App() {
  return (
    <Router>
      <AppContainer>
        <GlobalStyle />
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/success" element={<Success />} />  {/* Add this new route */}
          <Route path="/app" element={<WebApp />} />  {/* Add this new route */}
        </Routes>
      </AppContainer>
    </Router>
  );
}

export default App;
