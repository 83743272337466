import React, { useState, useEffect } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { FaGoogle, FaUsers, FaImage, FaUser, FaBuilding, FaPalette, FaUserFriends, FaCat } from 'react-icons/fa';
import { auth } from './firebase';
import { 
  signInWithPopup, 
  GoogleAuthProvider, 
  onAuthStateChanged,
  sendSignInLinkToEmail,
  isSignInWithEmailLink,
  signInWithEmailLink,
  signOut
} from 'firebase/auth';
import { Link, useNavigate } from 'react-router-dom';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background-color: #000;
    color: #fff;
    overflow-x: hidden; // Prevent horizontal scrolling
  }

  * {
    box-sizing: inherit;
  }
`;

const PageWrapper = styled.div`
  width: 100%;
  max-width: 100vw;
  overflow-x: hidden;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const MainContent = styled.main`
  flex: 1;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
`;

const Hero = styled.section`
  background: url('path-to-your-image.jpg') no-repeat center center/cover;
  background-color: rgba(0, 0, 0, 0.5); /* Fallback color */
  background-blend-mode: darken;
  width: 100%;
  padding: 100px 20px;
  color: #fff; /* Light text color for better contrast */
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #111; /* Dark background for hero */

  @media (max-width: 768px) {
    padding: 60px 20px;
  }
`;

const HeroTitle = styled.h1`
  font-size: 4em;
  margin: 0;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 2.5em;
    margin-top: 20px;
  }
`;

const HeroSubtitle = styled.p`
  font-size: 2em; /* Increased font size */
  margin: 20px 0;
  font-weight: bold; /* Added bold font weight */

  @media (max-width: 768px) {
    font-size: 1.5em;
  }
`;

const Button = styled(Link)`
  display: inline-block;
  background-color: #4CAF50;
  color: white;
  padding: 15px 30px;
  text-decoration: none;
  border-radius: 5px;
  font-size: 18px;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: #45a049;
  }
`;

const EmailInput = styled.input`
  padding: 15px;
  font-size: 18px;
  border: none;
  border-radius: 5px;
  margin-right: 10px;
  width: 300px;
  margin-left: 10px;
  max-width: 100%;

`;

const SignUpButton = styled(Button)`
  padding: 15px 30px;
  background-color: #ff5722;
  &:hover {
    background-color: #e64a19;
  }
`;

const HeroForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  width: 100%;
  max-width: 300px; // Reduced from 400px
  background-color: rgba(255, 255, 255, 0.1); // Semi-transparent background
  padding: 30px 40px; // 30px for top and bottom, 40px for left and right
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    max-width: 100%;
    padding: 20px;
  }
`;

const HeroEmailInput = styled(EmailInput)`
  margin-bottom: 15px;
  width: 100%;
  max-width: 275px; // Increased from 240px
  background-color: rgba(255, 255, 255, 0.9); // More opaque background for input
  color: #000; // Dark text for better contrast
  text-align: center; // Center the text inside the input

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

const HeroSignUpButton = styled(SignUpButton)`
  width: 100%;
  max-width: 260px; // Match the new max-width of HeroEmailInput
  margin-bottom: 15px;
  padding: 10px 20px;
  font-size: 16px;

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

const GoogleSignInButton = styled(Button)`
  background-color: #4285F4;
  width: 100%;
  max-width: 260px;
  padding: 10px 20px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: #357ae8;
  }

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

const GoogleIcon = styled(FaGoogle)`
  margin-right: 10px;
`;

const Header = styled.header`
  background-color: transparent;
  color: white;
  padding: 20px 0;
  width: 100%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
`;

const Logo = styled.img`
  height: 50px;
`;

const SubTitle = styled.h2`
  color: #fff; /* Changed font color to white */
  font-size: 2em;
  margin: 20px 0;
`;

const Content = styled.div`
  margin: 40px 0;
  padding: 20px;
  background: #111; /* Dark background for content */
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 800px;
  color: #fff; /* Light text color for better contrast */
`;

const FloatingSignUp = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(17, 17, 17, 0.9);
  color: white;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const EmailForm = styled.div`
  display: flex;
  align-items: center;
`;

const ResponsiveImage = styled.img`
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
`;

const Footer2 = styled.footer`
  background-color: #111;
  color: #fff;
  text-align: center;
  padding: 20px 0;
  width: 100%;
  margin-top: 60px; // Add extra space above the footer
`;

const FooterLink = styled.a`
  color: #fff;
  text-decoration: none;
  font-size: 14px;
  &:hover {
    text-decoration: underline;
  }
`;

const DashboardButton = styled.button`
  display: inline-block;
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 5px;
  font-size: 16px;
  transition: background-color 0.3s ease;
  border: none;
  cursor: pointer;
  width: 100%;
  max-width: 260px;
  &:hover {
    background-color: #45a049;
  }
`;

const LoadingOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`;

const LoadingText = styled.p`
  color: white;
  font-size: 24px;
`;

const HeaderButtons = styled.div`
  display: flex;
  gap: 10px;
`;

const LogoutButton = styled.button`
  background-color: #f44336;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #d32f2f;
  }
`;

const InfoBanner = styled.div`
  background-color: #4CAF50;
  color: white;
  text-align: center;
  padding: 10px;
  font-weight: bold;
  margin-top: 10px;
  border-radius: 5px;
`;

const GuideWorkflow = styled.div`
  margin-top: 60px;
  text-align: center;
  background-color: #1a1a1a;
  padding: 40px 20px; // Added horizontal padding
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
`;

const WorkflowTitle = styled.h3`
  font-size: 32px;
  margin-bottom: 40px;
  color: white;
  letter-spacing: 2px;
`;

const WorkflowSteps = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 30px;
`;

const WorkflowStep = styled.div`
  flex: 1;
  min-width: 200px;
  max-width: 300px;
  background-color: #222;
  padding: 30px;
  border-radius: 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  margin-bottom: 20px; // Added margin-bottom for better spacing on mobile

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(76, 175, 80, 0.3);
  }
`;

const StepNumber = styled.span`
  font-size: 48px;
  font-weight: bold;
  color: white;
  display: block;
  margin-bottom: 15px;
`;

const StepText = styled.span`
  font-size: 18px;
  line-height: 1.5;
  color: #ddd;
`;

const SocialProof = styled.div`
  background-color: #1a1a1a;
  padding: 40px 20px; // Added horizontal padding
  border-radius: 15px;
  margin-top: 60px;
  text-align: center;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
`;

const SocialProofTitle = styled.h3`
  font-size: 32px;
  color: #fff;
  margin-bottom: 30px;
`;

const SocialProofGrid = styled.div`
  display: flex;
  justify-content: center;
  gap: 40px;
  flex-wrap: wrap; // Allow items to wrap on smaller screens
`;

const SocialProofItem = styled.div`
  background-color: #222;
  padding: 30px;
  border-radius: 10px;
  transition: transform 0.3s ease;
  flex: 0 1 250px;
  margin-bottom: 20px; // Added margin-bottom for better spacing on mobile

  &:hover {
    transform: translateY(-5px);
  }
`;

const SocialProofIcon = styled.div`
  font-size: 48px;
  color: #4CAF50;
  margin-bottom: 15px;
`;

const SocialProofNumber = styled.div`
  font-size: 36px;
  font-weight: bold;
  color: #fff;
  margin-bottom: 10px;
`;

const SocialProofText = styled.p`
  font-size: 18px;
  color: #ddd;
`;

const UseCaseSection = styled.div`
  background-color: #1a1a1a;
  padding: 60px 20px;
  border-radius: 15px;
  margin-top: 60px;
  text-align: center;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
`;

const UseCaseTitle = styled.h3`
  font-size: 32px;
  color: #fff;
  margin-bottom: 30px;
`;

const UseCaseGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  justify-content: center;
  max-width: 1000px;
  margin: 0 auto;
`;

const UseCaseItem = styled.div`
  background-color: #222;
  padding: 30px;
  border-radius: 10px;
  transition: all 0.3s ease;
  cursor: pointer;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(76, 175, 80, 0.3);
  }

  ${props => props.selected && `
    background-color: #4CAF50;
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(76, 175, 80, 0.5);

    ${UseCaseIcon} {
      color: #fff;
    }

    ${UseCaseText} {
      color: #fff;
    }
  `}
`;

const UseCaseIcon = styled.div`
  font-size: 48px;
  color: #4CAF50;
  margin-bottom: 15px;
`;

const UseCaseText = styled.p`
  font-size: 18px;
  color: #ddd;
`;

const LandingPage = () => {
  const [user, setUser] = useState(null);
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const [selectedUseCase, setSelectedUseCase] = useState('Generate custom art of your favorite characters with the exact same art style as your images');

  const useCases = [
    { icon: <FaUser />, text: "Generate art and pictures of yourself with 100% resemblance in different styles and settings" },
    { icon: <FaUserFriends />, text: "Generate art and pictures of your family and friends with 100% resemblance in different styles and settings" },
    { icon: <FaPalette />, text: "Generate custom art of your favorite characters with the exact same art style as your images" },
    { icon: <FaCat />, text: "Generate pictures and art of your pet with 100% resemblance in different styles and settings" },
  ];

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const useCaseImages = {
    "Generate art and pictures of yourself with 100% resemblance in different styles and settings": "",
    "Generate art and pictures of your family and friends with 100% resemblance in different styles and settings": "",
    "Generate custom art of your favorite characters with the exact same art style as your images": isMobile ? "/show2mobile.png" : "/show2.png",
    "Generate pictures and art of your pet with 100% resemblance in different styles and settings": isMobile ? "/show4mobile.png" : "/show4.png",
  };

  const handleUseCaseClick = (text) => {
    setSelectedUseCase(text);
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        setTimeout(() => {
          navigate('/app');
        }, 5000);
      }
    });

    if (isSignInWithEmailLink(auth, window.location.href)) {
      let email = window.localStorage.getItem('emailForSignIn');
      if (!email) {
        email = window.prompt('Please provide your email for confirmation');
      }
      setIsLoading(true); // Start loading
      signInWithEmailLink(auth, email, window.location.href)
        .then((result) => {
          window.localStorage.removeItem('emailForSignIn');
        //   console.log("User signed in with email link:", result.user);
          setTimeout(() => {
            setIsLoading(false); // Stop loading after 2 seconds
            navigate('/app');
          }, 3000);
        })
        .catch((error) => {
          console.error("Error signing in with email link:", error);
          setIsLoading(false); // Stop loading in case of error
          // Handle error (e.g., show error message to user)
        });
    }

    return () => unsubscribe();
  }, [navigate]);

  const signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      // User is signed in
    //   console.log("Signed in user:", result.user);
      // You can add additional logic here, such as redirecting to a dashboard
    } catch (error) {
      console.error("Error signing in with Google:", error);
      // Handle errors here, such as displaying an error message to the user
    }
  };

  const goToDashboard = () => {
    console.log("Attempting to navigate to dashboard");
    navigate('/app');
  };

  useEffect(() => {
    // console.log("Current user state:", user);
  }, [user]);

  const sendSignInLink = async (e) => {
    e.preventDefault();
    const actionCodeSettings = {
      url: window.location.href, // Use the current URL
      handleCodeInApp: true,
    };

    try {
      await sendSignInLinkToEmail(auth, email, actionCodeSettings);
      window.localStorage.setItem('emailForSignIn', email);
      alert('Check your inbox and click the link in the email to start creating AI pictures!');
    } catch (error) {
      console.error("Error sending sign-in link:", error);
      alert('Error sending sign-in link. Please try again.');
    }
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      console.log("User signed out successfully");
      // You can add additional logic here, such as showing a message to the user
    } catch (error) {
      console.error("Error signing out:", error);
      // Handle errors here, such as displaying an error message to the user
    }
  };

  return (
    <>
      <GlobalStyle />
      <PageWrapper>
        {isLoading && (
          <LoadingOverlay>
            <LoadingText>Setting up your account...</LoadingText>
          </LoadingOverlay>
        )}
        <MainContent>
          <Header>
            <Logo src="/logo.png" alt="PicTrain Logo" />
            <HeaderButtons>
              {user ? (
                <>
                  <DashboardButton onClick={goToDashboard}>Go to App</DashboardButton>
                  <LogoutButton onClick={handleLogout}>Logout</LogoutButton>
                </>
              ) : (
                <GoogleSignInButton onClick={signInWithGoogle}>
                  <GoogleIcon /> Sign in with Google
                </GoogleSignInButton>
              )}
            </HeaderButtons>
          </Header>
          <Hero>
            <HeroTitle>Create your custom AI image generator in 2 minutes</HeroTitle>
            <HeroSubtitle>And generate custom images in a few seconds</HeroSubtitle> 
            <InfoBanner>Simple and easy to use, no coding required</InfoBanner>
            <HeroForm>
              <HeroEmailInput 
                type="email" 
                placeholder="Enter your email" 
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <HeroSignUpButton onClick={sendSignInLink}>Start Creating</HeroSignUpButton>
              {!user ? (
                <GoogleSignInButton onClick={signInWithGoogle}>
                  <GoogleIcon /> Continue with Google
                </GoogleSignInButton>
              ) : (
                <DashboardButton onClick={goToDashboard}>
                  Go to App
                </DashboardButton>
              )}
            </HeroForm>
          </Hero>
          
          <Container>
            <Content>
              <SubTitle>Tired of generic results from AI image generators that fail to meet your specific requirements and pictures?</SubTitle>
            </Content>
            <Content>
            <SubTitle>With Pictrain, you can generate high-quality, custom, and consistent images tailored to your specific pictures and needs in just a few seconds</SubTitle>
              <UseCaseSection>
                <UseCaseTitle>Explore popular use cases</UseCaseTitle>
                <UseCaseGrid>
                  {useCases.map((useCase, index) => (
                    <UseCaseItem 
                      key={index} 
                      onClick={() => handleUseCaseClick(useCase.text)}
                      selected={selectedUseCase === useCase.text}
                    >
                      <UseCaseIcon>{useCase.icon}</UseCaseIcon>
                      <UseCaseText>{useCase.text}</UseCaseText>
                    </UseCaseItem>
                  ))}
                </UseCaseGrid>
              </UseCaseSection>
              
              {selectedUseCase && useCaseImages[selectedUseCase] && (
                <ResponsiveImage 
                  src={useCaseImages[selectedUseCase]} 
                  alt={`Illustration for ${selectedUseCase}`} 
                />
              )}

              <GuideWorkflow>
                <WorkflowTitle>Quick steps to Custom AI Images</WorkflowTitle>
                <WorkflowSteps>
                  <WorkflowStep>
                    <StepNumber>1</StepNumber>
                    <StepText>Upload 5-15 images showcasing your desired style</StepText>
                  </WorkflowStep>
                  <WorkflowStep>
                    <StepNumber>2</StepNumber>
                    <StepText>Wait 2 minutes for your custom AI image generator to be created</StepText>
                  </WorkflowStep>
                  <WorkflowStep>
                    <StepNumber>3</StepNumber>
                    <StepText>Generate your custom images</StepText>
                  </WorkflowStep>
                </WorkflowSteps>
              </GuideWorkflow>
              <SocialProof>
                <SocialProofTitle>Join our growing community</SocialProofTitle>
                <SocialProofGrid>
                  <SocialProofItem>
                    <SocialProofIcon><FaUsers /></SocialProofIcon>
                    <SocialProofNumber>500+</SocialProofNumber>
                    <SocialProofText>Active users already creating their own custom AI images</SocialProofText>
                  </SocialProofItem>
                  <SocialProofItem>
                    <SocialProofIcon><FaImage /></SocialProofIcon>
                    <SocialProofNumber>10,000+</SocialProofNumber>
                    <SocialProofText>Custom AI images generated</SocialProofText>
                  </SocialProofItem>
                </SocialProofGrid>
              </SocialProof>

  

              <Footer2>
                <FooterLink href="/terms-of-service">ToS and Privacy Policy</FooterLink>
              </Footer2>
            </Content>
          </Container>
        </MainContent>
        
      </PageWrapper>
    </>
  );
};

export default LandingPage;