import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { auth } from './firebase';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios'; // Make sure to import axios
import { FaCheck } from 'react-icons/fa'; // Add this import at the top of the file

// Initialize Firestore with the same app instance

// Initialize Stripe payments with the same Firestore instance
const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`;

const ToggleLabel = styled.span`
  margin: 0 10px;
  color: ${props => props.isActive ? '#fff' : '#888'};
`;

const ToggleSwitch = styled.label`
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
`;

const ToggleSlider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 34px;

  &:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
  }
`;

const ToggleInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + ${ToggleSlider} {
    background-color: #2196F3;
  }

  &:checked + ${ToggleSlider}:before {
    transform: translateX(26px);
  }
`;

const PricingSection = styled.div`
  display: flex;
  justify-content: center;
  gap: 30px;
  margin: 40px auto;
  flex-wrap: wrap;
  // mobile
  @media (max-width: 768px) {
    gap: 10px;
  }
`;

const PricingBox = styled.div`
  background-color: #2c3e50;
  border-radius: 10px;
  padding: 30px;
  width: 250px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }

  // mobile
  @media (max-width: 768px) {
    max-width: 72%;
  }
`;

const PackageTitle = styled.h3`
  font-size: 1.8em;
  margin-bottom: 20px;
  color: #ecf0f1;
`;

const CreditsDisplay = styled.div`
  font-size: 2.5em;
  font-weight: bold;
  color: #3498db;
  margin-bottom: 20px;
`;

const EstimationContainer = styled.div`
  margin-bottom: 30px;
  text-align: left;
`;

const EstimationDetail = styled.div`
  color: #bdc3c7;
  font-style: italic;
  margin-top: 5px;
  display: flex;
  align-items: center;
`;

const CheckIcon = styled(FaCheck)`
  color: #2ecc71;
  margin-right: 5px;
  min-width: 16px;
  min-height: 16px;
`;

const PriceContainer = styled.div`
  margin-top: 20px;
`;

const OriginalPrice = styled.span`
  font-size: 1.2em;
  text-decoration: line-through;
  color: #95a5a6;
  display: block;
`;

const CurrentPrice = styled.span`
  font-size: 2em;
  font-weight: bold;
  color: #2ecc71;
`;

const DiscountBadge = styled.span`
  background-color: #e74c3c;
  color: white;
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 0.8em;
  position: absolute;
  top: -10px;
  right: -10px;
  // mobile
  @media (max-width: 768px) {
    font-size: 0.7em;
  }
`;

const PricingBoxButton = styled.button`
  background-color: #3498db;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1em;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 20px;

  &:hover {
    background-color: #2980b9;
  }

  &:disabled {
    background-color: #95a5a6;
    cursor: not-allowed;
  }
`;

const CenteredHeading = styled.h1`
  text-align: center;
  margin-bottom: 20px;
  // mobile
  @media (max-width: 768px) {
    font-size: 1.5em;
  }
`;

const Footer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;
`;

const PayButton = styled.button`
  background-color: #4CAF50;
  color: white;
  padding: 15px 30px;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #45a049;
  }

  &:disabled {
    background-color: #888;
    cursor: not-allowed;
  }
`;

const PricingInfoText = styled.p`
  font-size: 0.9em;
  color: #888;
  text-align: center;
  margin-top: 20px;
  padding: 0 20px;

  @media (max-width: 768px) {
    font-size: 0.8em;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const SubHeading = styled.p`
  text-align: center;
  font-size: 1.1em;
  color: #888;
  margin-bottom: 30px;
  margin-left: 20px;
  margin-right: 20px;
  // mobile
  @media (max-width: 768px) {
    font-size: 1em;
  }
`;

const PaymentOptions = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 40px;
  margin-bottom: 40px;
  flex-wrap: wrap;
  padding: 0 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const PaymentButton = styled.button`
  background-color: ${props => props.disabled ? '#888' : '#4CAF50'};
  color: white;
  padding: 15px 30px;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${props => props.disabled ? '#888' : '#45a049'};
  }

  @media (max-width: 768px) {
    width: 100%;
    max-width: 300px;
  }
`;

const Greeting = styled.p`
  font-size: 1.2em;
  text-align: center;
  margin-bottom: 20px;
  color: #4CAF50;
`;

const EmailHighlight = styled.span`
  font-weight: bold;
  color: #2196F3;
`;

const CustomInput = styled.input`
  width: 80%;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 15px;
  border: 1px solid #4CAF50;
  border-radius: 5px;
  font-size: 1em;
  text-align: center;

  @media (max-width: 768px) {
    width: 80%;
  }
`;

const CustomAmountDisplay = styled.div`
  font-size: 2.5em;
  font-weight: bold;
  margin-bottom: 20px;
`;

const NotificationBar = styled.div`
  background-color: #4CAF50;
  color: white;
  text-align: center;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
`;

const BoldText = styled.span`
  font-weight: bold;
`;

const Checkout = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [userEmail, setUserEmail] = useState(null);
  const [userId, setUserId] = useState(null);
  const [creditsSpent, setCreditsSpent] = useState(null);
  const [isDiscountEligible, setIsDiscountEligible] = useState(false);

  const packages = {
    small: { price: '$5', credits: '5 credits', stripePriceId: 'price_1Pux8T03eI7eG1Nf0E5BrCED', estimatedPictures: 33, estimatedModels: 1 },
    medium: { price: '$20', credits: '20 credits', stripePriceId: 'price_1Pux8T03eI7eG1NfYzZnCaoX', estimatedPictures: 133, estimatedModels: 6 },
    large: { price: '$50', credits: '50 credits', stripePriceId: 'price_1Pux8T03eI7eG1Nf5ntcIY2L', estimatedPictures: 333, estimatedModels: 16 },
  };

  useEffect(() => {
    const fetchUserData = async (user) => {
    //   console.log('Fetching user data for:', user.uid);
      setUserEmail(user.email);
      setUserId(user.uid);
      await fetchCreditsSpent(user.uid);
    };

    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        console.log('User authenticated:', user.uid);
        fetchUserData(user);
      } else {
        console.log("No user is currently signed in.");
        setUserEmail(null);
        setUserId(null);
        setCreditsSpent(null);
        setIsDiscountEligible(false);
      }
    });

    return () => unsubscribe();
  }, []);

  const fetchCreditsSpent = async (uid) => {
    // console.log('Fetching credits spent for user:', uid);
    try {
      const response = await axios.get(`https://fastapi-app-ok2y6f2snq-uc.a.run.app/credits?user_id=${uid}`);
    //   console.log('API response:', response.data);
      
      if (response.data && typeof response.data.credits_spent === 'number') {
        // console.log('Credits spent:', response.data.credits_spent);
        setCreditsSpent(response.data.credits_spent);
        setIsDiscountEligible(response.data.credits_spent <= 0.3);
      } else {
        // console.log('Invalid or missing credits_spent in response. Setting to 0.');
        setCreditsSpent(0);
        setIsDiscountEligible(true);
      }
    } catch (error) {
      console.error('Error fetching credits spent:', error);
    //   console.log('Setting credits spent to 0 due to error.');
      setCreditsSpent(0);
      setIsDiscountEligible(true);
    }
    
    // console.log('Final creditsSpent value:', creditsSpent);
    // console.log('Is discount eligible:', isDiscountEligible);
  };

  const handlePayment = async (packageKey) => {
    setIsLoading(true);
    
    try {
      const stripe = await stripePromise;
      const priceId = packages[packageKey].stripePriceId;
      let amount = parseInt(packages[packageKey].price.replace('$', ''));

      // Apply 25% discount if eligible
      if (isDiscountEligible) {
        amount = Math.round(amount * 0.75);
      }

      const response = await fetch('https://create-checkout-session-ok2y6f2snq-uc.a.run.app', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          priceId: priceId,
          amount: amount,
          successUrl: `${window.location.origin}/success`,
          cancelUrl: `${window.location.origin}/checkout`,
          userId: userId,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const { sessionId } = await response.json();

      const { error } = await stripe.redirectToCheckout({
        sessionId: sessionId,
      });

      if (error) {
        console.error('Error:', error);
        alert('An error occurred. Please try again.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const formatPrice = (price) => {
    return parseFloat(price.replace('$', '')).toFixed(2);
  };

  const getDiscountRate = (packageKey) => {
    if (creditsSpent <= 0.3) {
      return 0.25; // 25% discount for all packages
    } else {
      if (packageKey === 'medium') return 0.10; // 10% off for $20 package
      if (packageKey === 'large') return 0.15; // 15% off for $50 package
    }
    return 0; // No discount for small package or other cases
  };

  return (
    <div>
      <CenteredHeading>Choose your credits package</CenteredHeading>
      <SubHeading>Credits are the currency used in Pictrain. You can purchase credits in packages. Credits can be used to generate AI custom images or create your own AI custom image generator.</SubHeading>

      <PricingSection>
        {Object.entries(packages).map(([key, value]) => {
          const discountRate = getDiscountRate(key);
          const originalPrice = formatPrice(value.price);
          const discountedPrice = (originalPrice * (1 - discountRate)).toFixed(2);
          
          return (
            <PricingBox key={key}>
              {discountRate > 0 && <DiscountBadge>{Math.round(discountRate * 100)}% OFF</DiscountBadge>}
              <PackageTitle>{key.charAt(0).toUpperCase() + key.slice(1)} Package</PackageTitle>
              <CreditsDisplay>{value.credits}</CreditsDisplay>
              <EstimationContainer>
                <EstimationDetail><CheckIcon /> Generate {value.estimatedPictures} AI custom images</EstimationDetail>
                <EstimationDetail><CheckIcon /> Create {value.estimatedModels} AI custom image {value.estimatedModels === 1 ? 'generator' : 'generators'}</EstimationDetail>
                <EstimationDetail><CheckIcon /> Commercial use included</EstimationDetail>
              </EstimationContainer>
              <PriceContainer>
                {discountRate > 0 && <OriginalPrice>${originalPrice}</OriginalPrice>}
                <CurrentPrice>${discountedPrice}</CurrentPrice>
              </PriceContainer>
              <PricingBoxButton
                disabled={isLoading}
                onClick={() => handlePayment(key)}
              >
                {isLoading ? 'Processing...' : 'Buy'}
              </PricingBoxButton>
            </PricingBox>
          );
        })}
      </PricingSection>
      <PricingInfoText>
        <span>0.15 credits per custom image  </span>
        <span>· 3 credits for creating your own custom AI image generator</span>
      </PricingInfoText>
    </div>
  );
};

const stripePromise = loadStripe('pk_live_51PufGf03eI7eG1Nf5ck8ESBKiBpIz7pVD5EEWWeLNTHUJfpVj63DsEVpfgIUfgGjLzRQQariv90ejHxnWn4Fvitw00gW5wfYVM');

export default Checkout;
