import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import axios from 'axios';
import { useMediaQuery } from 'react-responsive';

import { FaDownload, FaCopy, FaQuestionCircle, FaTrash } from 'react-icons/fa';
// Import Firebase
import { getAuth, onAuthStateChanged } from "firebase/auth";

import HelpForm from './HelpForm'; // Create this new component
import defaultGeneratorImage from './defaultgenerator.png';
import loadingGeneratorImage from './defaultgenerator.png'; // Add this import

const AppContainer = styled.div`
  display: flex;
  height: 100vh;

  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }
`;

const Sidebar = styled.div`
  width: 200px;
  background-color: #2c3e50;  // Darker background color
  color: #ecf0f1;  // Light text color for contrast
  padding: 20px;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    width: 100%;
    height: 60px;
    flex-direction: row;
    justify-content: space-around;
    padding: 0;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 1000;
  }
`;

const SidebarButton = styled.button`
  margin-bottom: 10px;
  padding: 10px;
  background-color: ${props => props.active ? '#3498db' : 'transparent'};
  color: #ecf0f1;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-align: left;
  font-size: 18px;
  display: flex;
  align-items: center;

  &:hover {
    background-color: ${props => props.active ? '#3498db' : '#34495e'};
  }

  @media (max-width: 768px) {
    width: auto;
    height: 100%;
    justify-content: center;
    align-items: center;
    padding: 5px;
    font-size: 12px;
    flex-direction: column;
    margin-bottom: 0;
  }
`;

const EmojiIcon = styled.span`
  margin-right: 10px;
  font-size: 24px;
  @media (max-width: 768px) {
    font-size: 20px;
    margin-right: 0;
    margin-bottom: 5px;
  }
`;

const CreditSection = styled.div`
  margin-top: auto;
  padding-top: 20px;
  border-top: 1px solid #34495e;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    position: fixed;
    top: 10px;
    right: 10px;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 5px;
    width: auto;
    background-color: rgba(44, 62, 80, 0.8);
    border-radius: 20px;
  }
`;

const CreditDisplay = styled.span`
  font-weight: bold;
  color: #ecf0f1;
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    font-size: 12px;
    margin-right: 5px;
  }
`;

const CreditEmoji = styled.span`
  margin-right: 5px;
  font-size: 20px;
  @media (max-width: 768px) {
    display: none;
  }
`;

const BuyCreditsButton = styled.button`
  background-color: #2ecc71;
  color: white;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-size: 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #27ae60;
  }

  @media (max-width: 768px) {
    width: 16px;
    height: 16px;
    font-size: 14px;
  }
`;

const MainContent = styled.div`
  flex-grow: 1;
  padding: 20px;
  overflow-y: auto;

  @media (max-width: 768px) {
    padding-bottom: 80px; // Add padding to account for the bottom menu
  }
`;

const Title = styled.h1`
  text-align: center;
`;

const Section = styled.div`
  margin-bottom: 20px;
`;

const Button = styled.button`
  padding: 10px 15px;
  margin-right: 10px;
`;

const InputContainer = styled.div`
  padding-right: 20px;
  margin-bottom: 20px;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid #3498db;
  border-radius: 4px;
  background-color: #34495e;
  color: #ecf0f1;

  &::placeholder {
    color: #c3d3d4;
  }
`;

const EstimatedTime = styled.span`
  font-style: italic;
  color: #7f8c8d;
  margin-left: 10px;
`;

const GeneratorGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 20px;

  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    justify-content: center;
    padding: 0 10px;
  }
`;

const GeneratorCard = styled.div`
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px;
  text-align: center;
  cursor: pointer; // Add this line to change the cursor on hover
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out; // Optional: add a subtle hover effect

  &:hover {
    transform: translateY(-2px); // Optional: slight lift effect on hover
    box-shadow: 0 4px 8px rgba(0,0,0,0.1); // Optional: add a shadow on hover
  }
`;

const GeneratorImage = styled.img`
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 4px;
  margin-bottom: 10px;
`;

const CreateGeneratorSection = styled(Section)`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 300px;
  background-color: #2c3e50;  // Very dark blue-gray
  padding: 20px;
  border-radius: 8px;
  color: #ecf0f1;  // Light text color for contrast
`;

const CreateGeneratorForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  flex-grow: 1;
`;

const FormBox = styled.div`
  background-color: #34495e;  // Slightly lighter than the outer box
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  width: 100%;
  max-width: 400px;
`;

const Guidance = styled.p`
  color: #bdc3c7;  // Light gray for secondary text
  font-style: italic;
  margin: 10px 0;
  text-align: center;
`;

const GuidanceNewGenerator = styled.p`
  color: #bdc3c7;  // Light gray for secondary text
  font-style: italic;
  margin: 10px 0;
  text-align: left;
`;

const CreateGeneratorButton = styled(Button)`
  margin-top: 20px;
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #3498db;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #2980b9;
  }

  &:disabled {
    background-color: #95a5a6;
    cursor: not-allowed;
  }

  @media (max-width: 768px) {
    margin-top: 20px;
    width: 100%;
  }
`;

const ButtonEmoji = styled.span`
  margin-right: 8px;
  font-size: 1.2em;
`;

const LoadingSpinner = styled.div`
  border: 2px solid #f3f3f3;
  border-top: 2px solid #3498db;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spin 1s linear infinite;
  margin-right: 8px;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const GeneratorGuide = styled.div`
  background-color: #34495e;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  color: #ecf0f1;
`;

const GuideText = styled.p`
  margin-bottom: 10px;
`;

const CreateCustomGeneratorButton = styled(Button)`
  background-color: #3498db;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 10px;

  &:hover {
    background-color: #2980b9;
  }
`;

const LoadingGeneratorCard = styled(GeneratorCard)`
  opacity: 0.7;
  cursor: not-allowed;
`;

const LoadingText = styled.p`
  font-style: italic;
  color: #bdc3c7;
`;

const ErrorMessage = styled.div`
  color: #ff6b6b;
  background-color: #fff5f5;
  border: 1px solid #ff6b6b;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
`;

const GenerationForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
`;

const GenerationInput = styled(Input)`
  width: 97%;
  // for mobile
  @media (max-width: 768px) {
    width: 91%;
  }
`;

const GenerationSelect = styled.select`
  width: 99%;
  padding: 8px;
  border: 1px solid #34495e;
  border-radius: 4px;
  background-color: #34495e;
  color: #ecf0f1;
`;

const SuccessMessage = styled.div`
  color: #4ade80;  // Lighter green color
  margin-top: 20px;  // More space between button and message
  font-size: 18px;  // Bigger font
  text-align: center;
`;

const ImageContainer = styled.div`
  width: 100%;
  height: 512px;
  margin-bottom: 20px;
  border: 1px solid #3498db;  // Match the border color with the theme
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  background-color: #2c3e50;  // Dark background to match the theme
`;

const GeneratedImage = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);  // Add a subtle shadow
`;

const DownloadOverlay = styled.div`
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  padding: 8px;
  cursor: pointer;
  transition: opacity 0.3s ease;
  opacity: 0.7;

  &:hover {
    opacity: 1;
  }
`;

const DownloadIcon = styled(FaDownload)`
  color: white;
  font-size: 20px;
`;

const PlaceholderBox = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
`;

const PlaceholderText = styled.p`
  color: #888;
  font-size: 18px;
  text-align: center;
`;

const LoadingButton = styled(Button)`
  position: relative;
  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }
`;

const GalleryGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;

  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 10px;
  }
`;

const GalleryItem = styled.div`
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;

  @media (max-width: 768px) {
    border-radius: 4px;
  }
`;

// Add this new styled component
const CreateNewGeneratorButton = styled(Button)`
  margin-bottom: 20px;
  background-color: #3498db;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #2980b9;
  }
`;

const CreateGeneratorWarning = styled.div`
  background-color: #34495e;  // Guide neutral color
  color: #ecf0f1;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 20px;
  text-align: center;
  font-weight: bold;
  border: 1px solid #3498db;
`;

const GalleryImageContainer = styled.div`
  position: relative;
  height: 250px;

  @media (max-width: 768px) {
    height: 150px;
  }
`;

const GalleryImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const GalleryPromptContainer = styled.div`
  padding: 10px;
  background-color: #34495e;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    padding: 5px;
  }
`;

const CustomSizeInputs = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 10px;
`;

const CustomSizeInput = styled(Input)`
  width: 80px;
`;

const GalleryPrompt = styled.p`
  margin: 0;
  font-size: 14px;
  color: #ecf0f1;
  flex-grow: 1;
  margin-right: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const CopyIconWrapper = styled.div`
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CopyIcon = styled(FaCopy)`
  color: #bdc3c7;
  cursor: pointer;
  font-size: 16px;
  &:hover {
    color: #ecf0f1;
  }
`;

const DeleteWarning = styled.p`
  color: #888;
  font-style: italic;
  text-align: center;
  margin-bottom: 20px;
`;

const LoadMoreButton = styled(Button)`
  display: block;
  margin: 20px auto 0;
`;


const MobileSidebar = styled(Sidebar)`
  @media (max-width: 768px) {
    width: 100%;
    height: 60px;
    flex-direction: row;
    justify-content: space-around;
    padding: 0;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 1000;
  }
`;





const MobileSidebarButton = styled(SidebarButton)`
  @media (max-width: 768px) {
    width: auto;
    height: 100%;
    justify-content: center;
    align-items: center;
    padding: 5px;
    font-size: 12px;
    flex-direction: column;
  }
`;

const ButtonText = styled.span`
  @media (max-width: 768px) {
    display: block;
    font-size: 10px;
    margin-top: 2px;
    text-align: center;
  }
`;

const MobileCreditSection = styled(CreditSection)`
  @media (max-width: 768px) {
    position: fixed;
    top: 10px;
    right: 10px;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 5px;
    width: auto;
    background-color: rgba(44, 62, 80, 0.8);
    border-radius: 20px;
  }
`;


const ImagePreviewContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
`;

const ImagePreview = styled.div`
  position: relative;
  width: 100px;
  height: 100px;
`;

const PreviewImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
`;

const DeleteIcon = styled(FaTrash)`
  position: absolute;
  top: 5px;
  right: 5px;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 5px;
  border-radius: 50%;
  cursor: pointer;
`;


function WebApp() {
  const [activeTab, setActiveTab] = useState('yourGenerators');
  const [generators, setGenerators] = useState([]);
  const [currentGenerator, setCurrentGenerator] = useState(null);
  const [generations, setGenerations] = useState([]);
  const [credits, setCredits] = useState(null);
  const [credits_spent, setCredits_spent] = useState(null);
  const [generationsLoaded, setGenerationsLoaded] = useState(false);
  const [generatorsLoaded, setGeneratorsLoaded] = useState(false);
  const [newGeneratorName, setNewGeneratorName] = useState('');
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isCreatingGenerator, setIsCreatingGenerator] = useState(false);
  const [error, setError] = useState(null);
  const [generationParams, setGenerationParams] = useState({
    prompt: '',
    generator: '',
    image_size: 'landscape_4_3',
    num_inference_steps: 50,
    guidance_scale: 3.5,
    num_images: 1,
    enable_safety_checker: false,
    output_format: 'png'
  });
  const [isTraining, setIsTraining] = useState(false);
  const [trainingProgress, setTrainingProgress] = useState(null);
  const [successLog, setSuccessLog] = useState(null);
  const [newGenerator, setNewGenerator] = useState(null);
  const [user, setUser] = useState(null);
  const [generatedImage, setGeneratedImage] = useState(null);
  const [authFinalized, setAuthFinalized] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [displayedGenerations, setDisplayedGenerations] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const imagesPerPage = 21;
  const [estimatedTime, setEstimatedTime] = useState(null);
  const generatorNameInputRef = useRef(null);
  const [showGenerationEstimate, setShowGenerationEstimate] = useState(false);
  const [previewImages, setPreviewImages] = useState([]);
  const [showInsufficientCreditsWarning, setShowInsufficientCreditsWarning] = useState(false);
  const [customSize, setCustomSize] = useState({ width: 1024, height: 1024 });
  const [totalGenerations, setTotalGenerations] = useState(0);
  const [defaultGeneratorLastImage, setDefaultGeneratorLastImage] = useState(null);
  const [creatingGeneratorName, setCreatingGeneratorName] = useState(null);
  const [creatingGeneratorImage, setCreatingGeneratorImage] = useState(null);
  const [creditsLoaded, setCreditsLoaded] = useState(false);

  const navigate = useNavigate();

  const API_URL = 'https://fastapi-app-ok2y6f2snq-uc.a.run.app'; // Update this with your local server URL

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      setAuthFinalized(true);
      if (currentUser) {
        // console.log('Current user:', currentUser);
        // console.log('User email:', currentUser.email);
        // console.log('User UID:', currentUser.uid);
      } else {
        console.log('No user is signed in.');
        navigate('/'); // Redirect to landing page
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  useEffect(() => {
    if (authFinalized && user) {
      fetchCredits();
      fetchGenerators();
      setDefaultGeneratorLastImage(defaultGeneratorImage);
    }
  }, [authFinalized, user]);

  const fetchCredits = async () => {
    try {
      const response = await axios.get(`${API_URL}/credits?user_id=${user.uid}`);
      if (response.data && typeof response.data.credits === 'number') {
        setCredits(response.data.credits);
        setCredits_spent(response.data.credits_spent);
        setCreditsLoaded(true);
      } else {
        console.error('Invalid credits data:', response.data);
        setError('Invalid credits data received');
      }
    } catch (err) {
      console.error('Error fetching credits:', err.response ? err.response.data : err.message);
      setError('Failed to fetch credits: ' + (err.response ? err.response.data.detail : err.message));
    }
  };

  const fetchGenerators = async () => {
    try {
      const response = await axios.get(`${API_URL}/models?user_id=${user.uid}`);
      // Sort the generators alphabetically by name
      const sortedGenerators = response.data.sort((a, b) => a.name.localeCompare(b.name));
      setGenerators(sortedGenerators);
      setGeneratorsLoaded(true);
      
      // If the user has no generators, set the active tab to 'newGenerator'
      if (sortedGenerators.length === 0) {
        setActiveTab('newGenerator');
      } else {
        setGenerationParams(prev => ({ ...prev, generator: "DEFAULT" }));
      }
    } catch (err) {
      setError('Failed to fetch generators');
    }
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    if (tab === 'generations' && !generationsLoaded) {
      fetchGenerations();
    }
  };

  const fetchGenerations = async (page = 1) => {
    try {
      const response = await axios.get(`${API_URL}/generations?user_id=${user.uid}&page=${page}&per_page=${imagesPerPage}`);
      const fetchedGenerations = response.data.generations;
      setTotalGenerations(response.data.total_count);
      
      if (page === 1) {
        // Merge locally added generations with fetched generations
        const mergedGenerations = [...generations.filter(gen => !gen.id), ...fetchedGenerations];
        setGenerations(mergedGenerations);
      } else {
        setGenerations(prevGenerations => [...prevGenerations, ...fetchedGenerations]);
      }
      
      setGenerationsLoaded(true);
      setCurrentPage(page);
    } catch (err) {
      setError('Failed to fetch generations');
    }
  };

  const handleImageUpload = (event) => {
    const files = Array.from(event.target.files);
    const newFiles = [...selectedFiles, ...files];
    if (newFiles.length > 20) {
      setError('You can upload a maximum of 20 images.');
      return;
    }
    setError(null);
    setSelectedFiles(newFiles);
    
    // Generate preview URLs for the new files
    const newPreviews = files.map(file => URL.createObjectURL(file));
    setPreviewImages(prevPreviews => [...prevPreviews, ...newPreviews]);
  };

  const removeImage = (index) => {
    setSelectedFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
    setPreviewImages(prevPreviews => prevPreviews.filter((_, i) => i !== index));
  };

  const createGenerator = async () => {
    if (!newGeneratorName.trim() || selectedFiles.length === 0) return;
    
    // Check if user has enough credits
    if (credits < 3) {
    //   setError(`You need 3 credits to create a new generator. You currently have {credits.toFixed(2)} credits.`);
      return;
    }

    setIsCreatingGenerator(true);
    setCreatingGeneratorName(newGeneratorName); // Set the name of the generator being created
    setCreatingGeneratorImage(URL.createObjectURL(selectedFiles[0])); // Use the first uploaded image
    setError(null);
    setSuccessLog(null);
    setShowInsufficientCreditsWarning(false);

    // Calculate estimated time
    // Calculate estimated time
    const estimatedMinutes = selectedFiles.length < 5 ? 2 : 3;
    setEstimatedTime(estimatedMinutes);

    try {
      const formData = new FormData();
      selectedFiles.forEach((file, index) => {
        formData.append(`files`, file);
      });
      formData.append('generatorName', newGeneratorName);
      formData.append('user_id', user.uid);

      console.log('Sending request to create generator...');
    //   console.log('Request payload:', {
    //     generatorName: newGeneratorName,
    //     user_id: user.uid,
    //     fileCount: selectedFiles.length
    //   });

      const response = await axios.post(`${API_URL}/process-images`, formData, {
        headers: { 
          'Content-Type': 'multipart/form-data'
        },
        timeout: 900000, // 15 minutes timeout
      });

    //   console.log('Received response:', response);

      if (response.data && response.data.status === 'success') {
        // console.log('Generator created successfully:', response.data);
        setNewGenerator(response.data);
        setSuccessLog(true); // Changed to boolean
        // Fetch updated generators instead of manually updating the state
        await fetchGenerators();
      } else {
        console.error('Unexpected response:', response);
        throw new Error(response.data?.message || 'Generator creation failed');
      }
    } catch (err) {
      console.error('Error creating generator:', err);
      if (err.response) {
        console.error('Error response:', err.response.data);
        console.error('Error status:', err.response.status);
        console.error('Error headers:', err.response.headers);
      } else if (err.request) {
        console.error('Error request:', err.request);
      }
      const errorMessage = err.response?.data?.detail || err.message;
      if (errorMessage.includes('Insufficient credits')) {
        setShowInsufficientCreditsWarning(true);
        await fetchCredits(); // Refresh credits after insufficient credits error
      } else {
        setError(`Failed to create generator: ${errorMessage}`);
      }
    } finally {
      setIsCreatingGenerator(false);
      setCreatingGeneratorName(null); // Reset the creating generator name
      setCreatingGeneratorImage(null);
      setEstimatedTime(null);
    }
  };



  const generateImage = async () => {
    if (!generationParams.prompt.trim()) return;

    setError(null);
    setGeneratedImage(null);
    setIsGenerating(true);
    setShowGenerationEstimate(true);

    try {
      // If no generator is selected or DEFAULT is selected, use "DEFAULT"
      const generatorToUse = !generationParams.model || generationParams.generator === "DEFAULT" ? "DEFAULT" : generationParams.model;
      
      const response = await axios.post(`${API_URL}/generate?user_id=${user.uid}`, {
        ...generationParams,
        model: generatorToUse
      });
      
      const newGeneration = response.data;
      if (!newGeneration.image_url) {
        throw new Error('No image URL in the response');
      }
      
      // Add the new generation to the beginning of the generations array
      setGenerations(prev => [newGeneration, ...prev]);
      setTotalGenerations(prev => prev + 1);
      setGeneratedImage(newGeneration.image_url);

      // Update the last generated image for the generator
      if (generatorToUse === "DEFAULT") {
        // Update the default generator's last generated image locally
        setDefaultGeneratorLastImage(newGeneration.image_url);
      } else {
        // Update the custom generator's last generated image
        setGenerators(prevGenerators => prevGenerators.map(generator => 
          generator.id === generatorToUse ? { ...generator, lastGeneratedImageUrl: newGeneration.image_url } : generator
        ));
      }

      fetchCredits(); // Refresh credits after generation
    } catch (err) {
      console.error('Error generating image:', err);
      const errorMessage = err.response?.data?.detail || err.message;
      if (errorMessage.includes('Insufficient credits')) {
        setError('insufficient_credits');
        await fetchCredits(); // Refresh credits after insufficient credits error
      } else {
        setError('Failed to generate image: ' + errorMessage);
      }
    } finally {
      setIsGenerating(false);
      setShowGenerationEstimate(false);
    }
  };

  const handleGenerationParamChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (name === 'image_size' && value === 'custom') {
      setGenerationParams(prev => ({
        ...prev,
        [name]: value,
        width: customSize.width,
        height: customSize.height
      }));
    } else {
      setGenerationParams(prev => ({
        ...prev,
        [name]: type === 'checkbox' ? checked : value
      }));
    }
  };

  const handleCustomSizeChange = (e) => {
    const { name, value } = e.target;
    setCustomSize(prev => ({
      ...prev,
      [name]: parseInt(value, 10)
    }));
    if (generationParams.image_size === 'custom') {
      setGenerationParams(prev => ({
        ...prev,
        [name]: parseInt(value, 10)
      }));
    }
  };

  const buyCredits = () => {
    navigate('/checkout');
  };


  
  const handleDownload = async (imageUrl, prompt) => {
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `generated_image_${prompt.substring(0, 21)}.png`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading image:', error);
      setError('Failed to download image');
    }
  };

  const loadMoreImages = () => {
    fetchGenerations(currentPage + 1);
  };

  useEffect(() => {
    if (activeTab === 'newGenerator' && generatorNameInputRef.current) {
      generatorNameInputRef.current.focus();
    }
  }, [activeTab]);

  // Add this new styled component
  const Notification = styled.div`
    background-color: #34495e;  // Dark blue-gray background
    color: #ecf0f1;  // Light text color
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 20px;
    text-align: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  `;

  const NotificationText = styled.span`
    flex-grow: 1;
  `;

  const NotificationButton = styled(Button)`
    background-color: #3498db;  // Blue button
    color: white;
    border: none;
    padding: 8px 15px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 5px;

    &:hover {
      background-color: #2980b9;  // Darker blue on hover
    }
  `;

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      // You can add a temporary success message here if you want
      console.log('Text copied to clipboard');
    }, (err) => {
      console.error('Could not copy text: ', err);
    });
  };

  const isMobile = useMediaQuery({ maxWidth: 768 });

  const QuickStartGuide = styled.div`
    background-color: #34495e;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
    color: #ecf0f1;
  `;

  const GuideSection = styled.div`
    margin-bottom: 15px;
  `;

  const GuideTitle = styled.h3`
    color: #3498db;
    margin-bottom: 10px;
  `;

  const GuideList = styled.ol`
    padding-left: 20px;
  `;

  const GuideListItem = styled.li`
    margin-bottom: 5px;
  `;

  const GuideTip = styled.p`
    font-style: italic;
    color: #bdc3c7;
    margin-top: 10px;
  `;

  const FileInputContainer = styled(InputContainer)`
    border-style: dashed;
    text-align: center;
  `;

  const FileInput = styled.input`
    display: none;
  `;

  const FileInputLabel = styled.label`
    display: inline-block;
    padding: 10px 15px;
    background-color: #3498db;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #2980b9;
    }
  `;

  const handleGeneratorClick = (generatorId) => {
    setGenerationParams(prev => ({ ...prev, generator: generatorId }));
    setActiveTab('create');
  };

  const InsufficientCreditsWarning = styled.div`
    background-color: #34495e;  // Dark blue-gray background
    color: #ecf0f1;  // Light text color
    border: 1px solid #f39c12;  // Yellow border
    border-radius: 8px;
    padding: 20px;
    margin-top: 20px;
    font-size: 16px;
    text-align: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  `;

  const WarningText = styled.p`
    margin-bottom: 15px;
  `;

  const WarningButton = styled(Button)`
    background-color: #28a745; // green
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-weight: bold;

    &:hover {
      background-color: #c0392b;  // Darker red on hover
    }
  `;

  const InsufficientCreditsError = styled.div`
    background-color: #34495e;  // Dark blue-gray background
    color: #ecf0f1;  // Light text color
    border: 1px solid #f39c12;  // Yellow border
    border-radius: 8px;
    padding: 20px;
    margin-top: 20px;
    font-size: 16px;
    text-align: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  `;

  const GetCreditsButton = styled(Button)`
    background-color: #28a745;
    color: white;
    border: none;
    padding: 10px 20px;
    margin-top: 10px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #218838;
    }
  `;

  const FAQSection = styled.div`
    margin-top: 20px;
  `;

  const FAQItem = styled.div`
    margin-bottom: 15px;
  `;

  const FAQQuestion = styled.h4`
    color: #3498db;
    margin-bottom: 5px;
  `;

  const FAQAnswer = styled.p`
    color: #ecf0f1;
  `;

  return (
    <AppContainer>
      {authFinalized && creditsLoaded ? (
        <>
          <MobileSidebar>
            <MobileSidebarButton 
              active={activeTab === 'yourGenerators'} 
              onClick={() => handleTabClick('yourGenerators')}
            >
              <EmojiIcon>🎨</EmojiIcon>
              <ButtonText>Generators</ButtonText>
            </MobileSidebarButton>
            <MobileSidebarButton 
              active={activeTab === 'generations'} 
              onClick={() => handleTabClick('generations')}
            >
              <EmojiIcon>🖼️</EmojiIcon>
              <ButtonText>Gallery</ButtonText>
            </MobileSidebarButton>
            <MobileSidebarButton 
              active={activeTab === 'help'} 
              onClick={() => handleTabClick('help')}
            >
              <EmojiIcon><FaQuestionCircle /></EmojiIcon>
              <ButtonText>Help</ButtonText>
            </MobileSidebarButton>
            <MobileCreditSection>
              <CreditDisplay>
                <CreditEmoji>💵</CreditEmoji>
                {credits !== null ? `${credits.toFixed(2)} credits` : 'Loading...'}
              </CreditDisplay>
              <BuyCreditsButton onClick={buyCredits}>+</BuyCreditsButton>
            </MobileCreditSection>
          </MobileSidebar>
          <MainContent>
            {activeTab === 'create' && credits !== null && credits < 0.15 && (
              <Notification>
                <NotificationText>
                  Generating an image requires 0.15 credits. You have {credits.toFixed(2)} credits.
                </NotificationText>
                <NotificationButton onClick={buyCredits}>
                  Get Credits
                </NotificationButton>
              </Notification>
            )}

            {activeTab === 'create' && (
              <Section>
                <h2>Generate a new image</h2>
                {generators.length === 0 && (
                      <GeneratorGuide>
                        <GuideText>
                          The Default Generator is a general AI image generator suitable for a wide range of image generation tasks, but it is not personalized to your specific style and preferences.
                        </GuideText>
                        <GuideText>
                          For personalized and specific results, we recommend creating your own custom AI image generator.
                          Custom generators learn from your specific images and produce results that match your style and preferences. 
                        </GuideText>
                        <GuideText>Create a new generator in just 2 minutes.</GuideText>
                        <CreateCustomGeneratorButton onClick={() => setActiveTab('newGenerator')}>
                          Create Custom Generator
                        </CreateCustomGeneratorButton>
                      </GeneratorGuide>
                    )}
                <ImageContainer>
                  {generatedImage ? (
                    <>
                      <GeneratedImage src={generatedImage} alt="Generated" />
                      <DownloadOverlay onClick={() => handleDownload(generatedImage, generationParams.prompt)}>
                        <DownloadIcon />
                      </DownloadOverlay>
                    </>
                  ) : (
                    <PlaceholderBox>
                      <PlaceholderText>Your generated image will appear here</PlaceholderText>
                    </PlaceholderBox>
                  )}
                </ImageContainer>
                <GenerationForm onSubmit={(e) => { e.preventDefault(); generateImage(); }}>
                  <GenerationInput
                    type="text"
                    name="prompt"
                    value={generationParams.prompt}
                    onChange={handleGenerationParamChange}
                    placeholder="Describe your image here..."
                  />
                  <GenerationSelect
                    name="generator"
                    value={generationParams.generator}
                    onChange={handleGenerationParamChange}
                  >
                    <option value="DEFAULT">Default Generator</option>
                    {generators.map(generator => (
                      <option key={generator.id} value={generator.id}>{generator.name}</option>
                    ))}
                  </GenerationSelect>
                  <GenerationSelect
                    name="image_size"
                    value={generationParams.image_size}
                    onChange={handleGenerationParamChange}
                  >
                    <option value="square_hd">Square</option>
                    <option value="portrait_4_3">Portrait 4:3</option>
                    <option value="portrait_16_9">Portrait 16:9</option>
                    <option value="landscape_4_3">Landscape 4:3</option>
                    <option value="landscape_16_9">Landscape 16:9</option>
                    <option value="custom">Custom size</option>
                  </GenerationSelect>
                  {generationParams.image_size === 'custom' && (
                    <CustomSizeInputs>
                      <CustomSizeInput
                        type="number"
                        name="width"
                        value={customSize.width}
                        onChange={handleCustomSizeChange}
                        placeholder="Width"
                        min="512"
                        max="1536"
                      />
                      <CustomSizeInput
                        type="number"
                        name="height"
                        value={customSize.height}
                        onChange={handleCustomSizeChange}
                        placeholder="Height"
                        min="512"
                        max="1536"
                      />
                    </CustomSizeInputs>
                  )}
                  <LoadingButton 
                    type="submit" 
                    disabled={isGenerating}
                  >
                    {isGenerating ? 'Generating...' : 'Generate'}
                    {isGenerating && <LoadingSpinner />}
                  </LoadingButton>
                </GenerationForm>

                {error === 'insufficient_credits' ? (
                  <InsufficientCreditsError>
                    <p>Generating an image requires 0.15 credits. You have {credits.toFixed(2)} credits.</p>
                    <GetCreditsButton onClick={buyCredits}>
                      Get Credits
                    </GetCreditsButton>
                  </InsufficientCreditsError>
                ) : error ? (
                  <ErrorMessage>{error}</ErrorMessage>
                ) : null}

                {showGenerationEstimate && (
                  <EstimatedTime>
                    Estimated time: 5-10 seconds
                  </EstimatedTime>
                )}
              </Section>
            )}

            {activeTab === 'newGenerator' && (
              <CreateGeneratorSection>
                <h2>Create Custom Generator</h2>
                {credits < 3 && (
                  <CreateGeneratorWarning>
                    Creating a new custom generator requires 3 credits. You currently have {credits.toFixed(2)} credits.<br></br>
                    <NotificationButton onClick={buyCredits} style={{ marginLeft: '10px' }}>
                      Get Credits
                    </NotificationButton>
                  </CreateGeneratorWarning>
                )}
                <Guidance>Once the generator is created, you will find it in the 'Generators' section.</Guidance>
                <CreateGeneratorForm>
                  <FormBox>
                    <h3>Generator Name</h3>
                    <InputContainer>
                      <Input
                        type="text"
                        value={newGeneratorName}
                        onChange={(e) => setNewGeneratorName(e.target.value)}
                        placeholder="My pet image generator"
                        ref={generatorNameInputRef}
                      />
                    </InputContainer>
                  </FormBox>
                  <FormBox>
                    <h3>Upload Images</h3>
                    <FileInputContainer>
                      <FileInputLabel htmlFor="file-upload">
                        Choose Images
                      </FileInputLabel>
                      <FileInput
                        id="file-upload"
                        type="file"
                        accept="image/*"
                        onChange={handleImageUpload}
                        multiple
                      />
                      <p>{selectedFiles.length} image{selectedFiles.length !== 1 ? 's' : ''} selected</p>
                    </FileInputContainer>
                    <Guidance>Upload at least 5 images for optimal results. The more images you provide, the better the generator can learn and create custom images tailored to your preferences
                </Guidance>



                    <ImagePreviewContainer>
                      {previewImages.map((preview, index) => (
                        <ImagePreview key={index}>
                          <PreviewImage src={preview} alt={`Preview ${index + 1}`} />
                          <DeleteIcon onClick={() => removeImage(index)} />
                        </ImagePreview>
                      ))}
                    </ImagePreviewContainer>
                  </FormBox>
                  <CreateGeneratorButton 
                    onClick={createGenerator} 
                    disabled={isCreatingGenerator}
                  >
                    
                    {isCreatingGenerator ? (
                      <>
                        <LoadingSpinner />
                        Creating...
                      </>
                    ) : (
                      <>
                        Create
                      </>
                    )}
                  </CreateGeneratorButton>
                </CreateGeneratorForm>
                <Guidance>Estimated time to create a new generator: approximately 2 minutes</Guidance>
                {showInsufficientCreditsWarning && (
                  <InsufficientCreditsWarning>
                    <WarningText>
                      Creating a new generator requires 3 credits. You have {credits.toFixed(2)} credits.
                    </WarningText>
                    <WarningButton onClick={buyCredits}>
                      Get Credits
                    </WarningButton>
                  </InsufficientCreditsWarning>
                )}
                {estimatedTime && (
                  <EstimatedTime>
                    Estimated time: {estimatedTime} minute{estimatedTime > 1 ? 's' : ''}
                    <br />
                    You can keep using the app while the generator is being created.
                  </EstimatedTime>
                )}
                {successLog && (
                  <SuccessMessage>Generator created successfully!</SuccessMessage>
                )}
              </CreateGeneratorSection>
            )}

            {activeTab === 'yourGenerators' && (
              <Section>
                <h2>Your Generators</h2>
                <CreateNewGeneratorButton onClick={() => setActiveTab('newGenerator')}>
                  Create Custom Generator
                </CreateNewGeneratorButton>
                {generatorsLoaded ? (
                  <>
                    
                    <Guidance>Click on a generator to begin generating images.</Guidance>
                    <GeneratorGrid>
                      <GeneratorCard onClick={() => {
                        setGenerationParams(prev => ({ ...prev, generator: "DEFAULT" }));
                        setActiveTab('create');
                      }}>
                        <GeneratorImage 
                          src={defaultGeneratorLastImage || defaultGeneratorImage} 
                          alt="Default Generator" 
                        />
                        <p>Default Generator</p>
                      </GeneratorCard>
                      {generators.map(generator => (
                        <GeneratorCard key={generator.id} onClick={() => {
                          setGenerationParams(prev => ({ ...prev, generator: generator.id }));
                          setActiveTab('create');
                        }}>
                          <GeneratorImage 
                            src={generator.lastGeneratedImageUrl || `https://via.placeholder.com/150?text=${generator.name}`} 
                            alt={generator.name} 
                          />
                          <p>{generator.name}</p>
                        </GeneratorCard>
                      ))}
                      {creatingGeneratorName && (
                        <LoadingGeneratorCard>
                          <GeneratorImage 
                            src={creatingGeneratorImage || loadingGeneratorImage} 
                            alt="Creating Generator" 
                          />
                          <p>{creatingGeneratorName}</p>
                          <LoadingText>Creating...</LoadingText>
                        </LoadingGeneratorCard>
                      )}
                    </GeneratorGrid>
                  </>
                ) : (
                  <p>Loading generators...</p>
                )}
              </Section>
            )}

            {activeTab === 'generations' && (
              <Section>
                <h2>Your Generated Images</h2>
                <DeleteWarning>
            Images are guaranteed to be available for at least 7 days. After that, they may be deleted at any time. We recommend that you download any images that you want to keep for longer.
                </DeleteWarning>
                {generationsLoaded ? (
                  generations.length === 0 ? (
                    <p>No generated images yet.</p>
                  ) : (
                    <>
                      <GalleryGrid>
                        {generations.map(gen => (
                          <GalleryItem key={gen.id}>
                            <GalleryImageContainer>
                              <GalleryImage src={gen.image_url} alt="Generated" />
                              <DownloadOverlay onClick={() => handleDownload(gen.image_url, gen.prompt)}>
                                <DownloadIcon />
                              </DownloadOverlay>
                            </GalleryImageContainer>
                            <GalleryPromptContainer>
                              <GalleryPrompt>{gen.prompt}</GalleryPrompt>
                              <CopyIconWrapper>
                                <CopyIcon onClick={() => copyToClipboard(gen.prompt)} />
                              </CopyIconWrapper>
                            </GalleryPromptContainer>
                          </GalleryItem>
                        ))}
                      </GalleryGrid>
                      {generations.length < totalGenerations && (
                        <LoadMoreButton onClick={loadMoreImages}>
                          Load More
                        </LoadMoreButton>
                      )}
                    </>
                  )
                ) : (
                  <p>Loading generations...</p>
                )}
              </Section>
            )}

            {activeTab === 'help' && (
              <Section>
                <QuickStartGuide>
                  <h3>Quick Start Guide</h3>
                  
                  <GuideSection>
                    <GuideTitle>Creating your custom generator</GuideTitle>
                    <GuideList>
                      <GuideListItem>Go to the "Generators" tab and click "Create Custom Generator".</GuideListItem>
                      <GuideListItem>Write a name for your generator.</GuideListItem>
                      <GuideListItem>Upload 5-15 relevant images that match the style of images you want to generate.</GuideListItem>
                      <GuideListItem>Click "Create" and wait 2 minutes for your custom generator to be created. It will appear in 'Generators' once it's ready.</GuideListItem>
                    </GuideList>
                  </GuideSection>

                  <GuideSection>
                    <GuideTitle>Generating Images</GuideTitle>
                    <GuideList>
                      <GuideListItem>Go to your generators page and click the generator you want to use.</GuideListItem>
                      <GuideListItem>Write a text prompt describing the image you want to generate.</GuideListItem>
                      <GuideListItem>Click "Generate" and wait a few seconds for the app to produce an image based on your prompt and generator.</GuideListItem>
                    </GuideList>
                  </GuideSection>
                  <GuideSection>
                    <GuideTitle>Tips</GuideTitle>
                    <GuideList>
                      <GuideListItem>Use high-resolution, high-quality images for best results when creating your generator</GuideListItem>
                      <GuideListItem>Use a mix of different images with varying situations and angles to create a generator that can generate a wide range of images.</GuideListItem>
                      <GuideListItem>Once you have generated some great pictures, try using them to create a new generator to further refine the results.</GuideListItem>
                      <GuideListItem>Experiment with different prompts and generators to discover new possibilities.</GuideListItem>
                    </GuideList>
                  </GuideSection>
                </QuickStartGuide>

                <FAQSection>
                  <h3>Frequently Asked Questions</h3>
                  <FAQItem>
                    <FAQQuestion>Can I try this for free?</FAQQuestion>
                    <FAQAnswer>
                    To help you get started, we've added 0.3 credits to your account absolutely free. These credits allow you to explore our platform and generate two images using our Default Generator, giving you a taste of the incredible visuals you can create.
Ready to take your visuals to the next level? Create a custom generator tailored to your specific needs for just 3 credits. Once your custom generator is set up, you can generate images for only 0.15 credits each. </FAQAnswer>
                    <FAQQuestion>Can I use the images I generate for commercial purposes?</FAQQuestion>
                    <FAQAnswer>
                    Yes, you can use the images you generate for commercial purposes if you have purchased credits. However, please ensure that the content of the images does not infringe on any existing copyrights, trademarks, or other intellectual property rights. You are responsible for the images you generate and their usage. 
                    </FAQAnswer>
                    <FAQQuestion>What kind of images should I upload to create a custom generator?</FAQQuestion>
                    <FAQAnswer>
                    You should upload a variety of images that show different angles, lighting, and expressions. This will help the generator learn the different variations of the subject. Aim for high-quality images that clearly represent the subject matter you want to generate.
                    </FAQAnswer>
                    <FAQQuestion>Is the generation uncensored?</FAQQuestion>
                    <FAQAnswer>
                      Yes, the images generated are not censored. You are responsible for the images you generate. We do not allow the generation of illegal or harmful content. Please use the tool responsibly and adhere to our terms of service.
                    </FAQAnswer>
                    <FAQQuestion>How many images should I upload to create a custom generator?</FAQQuestion>
                    <FAQAnswer>
                    We recommend uploading at least 5 images, but for optimal results, providing 10-20 images is ideal. The more high-quality images you upload, the better the generator will be at generating accurate and diverse images. 
                    </FAQAnswer>
                    <FAQQuestion>Can I generate images without creating a generator?</FAQQuestion>
                    <FAQAnswer>
                    Yes, you can generate images without creating a generator by using the default generator. Note that the default generator is a general one and may not be suitable for all use cases. For personalized results, we recommend creating a custom generator. 
                    </FAQAnswer>
                    <FAQQuestion>Why am I getting network error when creating a generator?</FAQQuestion>
                    <FAQAnswer>
                    If you're getting a network error when creating a generator.
Check your internet connection. Try using a stable WiFi connection instead of mobile data.
If the issue persists, try using a desktop browser instead of a mobile device. 
                    </FAQAnswer>
                    <FAQQuestion>Can I choose a custom size for the image I want to generate?</FAQQuestion>
                    <FAQAnswer>
                    Yes, you can choose a custom size for the image you want to generate. Select "Custom size" in the image size dropdown menu and then enter the desired width and height in the input fields. The width and height must be between 512 and 1536 pixels.
                    </FAQAnswer>
                    

                  </FAQItem>
                  {/* You can add more FAQ items here in the future */}
                </FAQSection>

                <h2>Any questions or feedback?</h2>

                <HelpForm 
                  onSubmit={async (formData) => {
                    setError(null);
                    try {
                      const response = await axios.post(`${API_URL}/help`, {
                        ...formData,
                        user_id: user.uid
                      });
                      if (response.data.message) {
                        setSuccessLog(response.data.message);
                      }
                    } catch (err) {
                      setError('Failed to submit help request: ' + (err.response?.data?.detail || err.message));
                    }
                  }}
                />
              </Section>
            )}
          </MainContent>
        </>
      ) : (
        <div>Loading...</div>
      )}
    </AppContainer>
  );
}

export default WebApp;
